@font-face {
  font-family: "iconfont"; /* Project id 3496237 */
  src: url('iconfont.woff2?t=1680876375651') format('woff2'),
       url('iconfont.woff?t=1680876375651') format('woff'),
       url('iconfont.ttf?t=1680876375651') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu:before {
  content: "\e62c";
}

.icon-swimming:before {
  content: "\e605";
}

.icon-swimmer:before {
  content: "\f0e9";
}

.icon-gitee:before {
  content: "\e60c";
}

.icon-brightness:before {
  content: "\e761";
}

.icon-setting:before {
  content: "\e62b";
}

.icon-dingtalk:before {
  content: "\e881";
}

.icon-twitter:before {
  content: "\e882";
}

.icon-youtube-fill:before {
  content: "\e883";
}

.icon-facebook-fill:before {
  content: "\e88d";
}

.icon-user-fill:before {
  content: "\e68b";
}

.icon-lightning-fill:before {
  content: "\e651";
}

.icon-copy:before {
  content: "\e606";
}

.icon-like:before {
  content: "\e616";
}

.icon-weibo:before {
  content: "\e62d";
}

.icon-like-fill:before {
  content: "\e60a";
}

.icon-back-top:before {
  content: "\e739";
}

.icon-browse:before {
  content: "\e613";
}

.icon-lightning:before {
  content: "\e617";
}

.icon-qq:before {
  content: "\e666";
}

.icon-check:before {
  content: "\e637";
}

.icon-github:before {
  content: "\e732";
}

.icon-wechat:before {
  content: "\e933";
}

.icon-return:before {
  content: "\e604";
}

.icon-interactive:before {
  content: "\e60b";
}

.icon-time:before {
  content: "\e9d0";
}

.icon-close:before {
  content: "\e673";
}

.icon-left:before {
  content: "\e652";
}

.icon-right:before {
  content: "\e653";
}

.icon-search:before {
  content: "\e655";
}

.icon-about-fill:before {
  content: "\e603";
}

.icon-comments-fill:before {
  content: "\e7a9";
}

.icon-heart-fill:before {
  content: "\e849";
}

.icon-calendar-fill:before {
  content: "\e84d";
}

.icon-zhihu:before {
  content: "\e87c";
}

.icon-rss:before {
  content: "\e600";
}

.icon-bottom:before {
  content: "\e76a";
}

.icon-top:before {
  content: "\e76d";
}

.icon-home:before {
  content: "\e7c6";
}

.icon-link:before {
  content: "\e7e2";
}

.icon-home-fill:before {
  content: "\e867";
}

.icon-notice:before {
  content: "\e8c4";
}

.icon-favorites-fill:before {
  content: "\e721";
}

.icon-daymode:before {
  content: "\e771";
}

.icon-nightmode:before {
  content: "\e773";
}

.icon-daymode-fill:before {
  content: "\e77c";
}

.icon-folder-fill:before {
  content: "\e77f";
}

.icon-discount-fill:before {
  content: "\e77d";
}

.icon-nightmode-fill:before {
  content: "\e784";
}

.icon-sticky:before {
  content: "\e788";
}

.icon-favorites:before {
  content: "\e7b3";
}

.icon-search-menu:before {
  content: "\e7b4";
}

